var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canSeeData())?_c('b-dropdown',{staticClass:"card-menu mr-1",attrs:{"toggle-class":"text-decoration-none","variant":"outline-secondary","lazy":"","no-caret":_vm.noCaret,"dropright":""}},[_c('template',{slot:"button-content"},[_vm._t("default"),_c('span',{staticClass:"sr-only"},[_vm._v("Options")])],2),_c('b-dropdown-header',[_vm._v(_vm._s(_vm.organisation.name))]),_c('b-dropdown-item',{attrs:{"to":{
      name: 'spotlight',
      params: { organisationId: _vm.link.organisationId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'hand-spock']}}),_vm._v("Spotlight ")],1),_c('b-dropdown-item',{attrs:{"to":{
      name: 'organisation-details',
      params: { organisationId: _vm.link.organisationId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'info-circle']}}),_vm._v("Details ")],1),(_vm.isArchived())?_c('b-dropdown-item',{attrs:{"to":{
      name: 'savings',
      params: { organisationId: _vm.link.organisationId }
    }}},[_c('font-awesome-icon',{staticClass:"text-muted mr-2",attrs:{"icon":['fas', 'pound-sign']}}),_vm._v("Savings "),_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Archived")])],1):_vm._e(),(_vm.organisation.connected)?_c('b-dropdown-item',{on:{"click":_vm.disconnect}},[_c('font-awesome-icon',{staticClass:"text-muted mr-1",attrs:{"icon":['fas', 'user-slash']}}),_vm._v("Disconnect ")],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }