<template>
  <div id="client-actions" class="mt-4">
    <div v-if="busy" class="text-center my-5">
      <Busy primary :size="4" />
      <p class="mt-4 mb-2 text-primary">Building your actions and contracts</p>
    </div>
    <div v-else>
      <!-- ACTIONS -->

      <h2>Actions</h2>

      <div
        id="actions"
        v-if="
          contractsToSign.length > 0 ||
          billsRequired.length > 0 ||
          purchasingRecommendations.length > 0
        "
      >
        <div class="reducer-divider"></div>
        <div class="actionSection" v-if="contractsToSign.length > 0">
          <h3>Contracts To Sign</h3>
          <b-table
            class="actionsTable"
            responsive
            :items="contractsToSign"
            :fields="contractsToSignFields"
            stacked="md"
          >
            <template #cell(product)="data"> {{ formatSupplyTypes(data.value) }}</template>
            <template #cell(outDate)="data"> {{ formatDate(data.value) }}</template>
          </b-table>
        </div>
        <div class="actionSection" v-if="billsRequired.length > 0">
          <h3>Bills Required</h3>
          <b-table
            class="actionsTable"
            responsive
            :items="billsRequired"
            :fields="billsRequiredFields"
            stacked="md"
          >
            <template #cell(type)="data"> {{ formatSupplyTypes(data.value) }}</template>
            <template #cell(averageSavings)="data">
              {{ $currencySymbol }}{{ getAverageSavings(data.item.type) }}</template
            >
            <template #cell(priority)="data"> {{ getPriority(data.item.type) }}</template>
            <template #cell(action)="data">
              <b-button
                class="tableButton"
                @click="$refs.fileUploadModal.show(data.item.organisationId, data.item)"
                ><span class="button-span">Upload Bill</span>
              </b-button></template
            >
          </b-table>
        </div>

        <div class="actionSection" v-if="purchasingRecommendations.length > 0">
          <h3>Purchasing Recommendations</h3>
          <b-table
            class="actionsTable"
            responsive
            :items="purchasingRecommendations"
            :fields="purchasingRecommendationsFields"
            stacked="md"
          >
            <template #cell(type)="data">
              {{ data.item.current ? formatSupplyTypes(data.item.current.type) : "" }}</template
            >
            <template #cell(saving)="data">
              {{ $currencySymbol }}{{ formatSaving(data.value) }}</template
            >
            <template #cell(offerMade)="data">
              {{
                data.item.recommended ? formatDate(data.item.recommended.createdDate) : ""
              }}</template
            >
            <template #cell(action)="data">
              <b-button
                class="tableButton"
                :to="{
                  name: 'savings',
                  params: {
                    organisationId: data.item.supply.organisationId,
                    scroll: data.item.supply.type
                  }
                }"
                ><span class="button-span">View Report</span>
              </b-button></template
            >
          </b-table>
        </div>
      </div>
      <div v-else>
        <p>You don't have any actions to complete right now.</p>
      </div>

      <!-- CONTRACTS -->

      <h2>Contracts</h2>
      <div
        id="contracts"
        v-if="
          contractsBeingSwitched.length > 0 ||
          contractsGoingLive.length > 0 ||
          liveContracts.length > 0
        "
      >
        <div class="reducer-divider"></div>
        <div id="contracts">
          <div class="actionSection" v-if="contractsBeingSwitched.length > 0">
            <h3>Contracts Being Switched</h3>
            <b-table
              class="actionsTable"
              responsive
              :items="contractsBeingSwitched"
              :fields="contractsBeingSwitchedFields"
              stacked="md"
            >
              <template #cell(type)="data"> {{ formatSupplyTypes(data.value) }}</template>
              <template #cell(endDate)="data"> {{ formatDate(data.value) }}</template>
              <template #cell(signedDate)="data"> {{ formatDate(data.value) }}</template>
            </b-table>
          </div>
          <div class="actionSection" v-if="contractsGoingLive.length > 0">
            <h3>Contracts Going Live</h3>
            <b-table
              class="actionsTable"
              responsive
              :items="contractsGoingLive"
              :fields="contractsGoingLiveFields"
              stacked="md"
            >
              <template #cell(type)="data"> {{ formatSupplyTypes(data.value) }}</template>
              <template #cell(endDate)="data"> {{ formatDate(data.value) }}</template>
              <template #cell(signedDate)="data"> {{ formatDate(data.value) }}</template>
            </b-table>
          </div>
          <div class="actionSection" v-if="liveContracts.length > 0">
            <h3>Live Contracts</h3>
            <b-table
              class="actionsTable"
              responsive
              :items="liveContracts"
              :fields="liveContractsFields"
              stacked="md"
            >
              <template #cell(type)="data"> {{ formatSupplyTypes(data.value) }}</template>
              <template #cell(contractExpiry)="data">
                {{
                  data.value == "2000-01-01T00:00:00" ? "Out of contract" : formatDate(data.value)
                }}</template
              >
              <template #cell(signedDate)="data"> {{ formatDate(data.value) }}</template>
            </b-table>
          </div>
        </div>
      </div>
      <div v-else>
        <p>You don't have any contracts at the moment.</p>
      </div>
    </div>
    <FileUploadModal ref="fileUploadModal" />
  </div>
</template>

<script>
import ApiHelper from "@/helper/apihelper";
import { EventBus } from "@/components/eventbus";
import Busy from "@/components/Busy";
import SupplyTypes from "@/helper/supplytypehelper";
import FormatHelper from "@/helper/formathelper";
import FileUploadModal from "@/components/modals/FileUploadModal";

export default {
  name: "ClientActions",
  components: {
    Busy,
    FileUploadModal
  },
  props: {
    organisation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      busy: null,
      supplies: null,
      supplySavings: {},
      contractsMap: null,
      currentDealsMap: null,
      recommendedDealsMap: null,
      //Sections
      liveContracts: [],
      contractsGoingLive: [],
      contractsBeingSwitched: [],
      contractsToSign: [],
      billsRequired: [],
      purchasingRecommendations: [],
      purchasingRecommendationsFields: [
        {
          key: "supply.name",
          label: "Site",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "current.supplierName",
          label: "Current Supplier",
          tdClass: "align-middle"
        },
        {
          key: "recommended.supplierName",
          label: "New Supplier",
          tdClass: "align-middle"
        },
        {
          key: "saving",
          label: "Saving",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "offerMade",
          label: "Offer Made",
          tdClass: "align-middle"
        },
        {
          key: "action",
          label: "Action",
          tdClass: ["align-middle", "text-center-md-important"],
          thClass: ["align-middle", "text-center"]
        }
      ],
      liveContractsFields: [
        {
          key: "name",
          label: "Site",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "supplierName",
          label: "Supplier",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "contractExpiry",
          label: "Contract Ends",
          sortable: true,
          tdClass: "align-middle"
        },
        {
          key: "signedDate",
          label: "Signed Date",
          sortable: true,
          tdClass: "align-middle"
        }
      ],
      contractsGoingLiveFields: [
        {
          key: "name",
          label: "Site",
          tdClass: "align-middle"
        },
        {
          key: "type",
          label: "Type",
          tdClass: "align-middle"
        },
        {
          key: "newSupplierName",
          label: "Supplier",
          tdClass: "align-middle"
        },
        {
          key: "endDate",
          label: "Contract Ends",
          tdClass: "align-middle"
        },
        {
          key: "signedDate",
          label: "Signed Date",
          tdClass: "align-middle"
        }
      ],
      contractsBeingSwitchedFields: [
        {
          key: "name",
          label: "Site",
          tdClass: "align-middle"
        },
        {
          key: "type",
          label: "Type",
          tdClass: "align-middle"
        },
        {
          key: "newSupplierName",
          label: "Supplier",
          tdClass: "align-middle"
        },
        {
          key: "endDate",
          label: "Contract Ends",
          tdClass: "align-middle"
        },
        {
          key: "signedDate",
          label: "Signed Date",
          tdClass: "align-middle"
        }
      ],
      billsRequiredFields: [
        {
          key: "name",
          label: "Site",
          tdClass: "align-middle"
        },
        {
          key: "type",
          label: "Type",
          tdClass: "align-middle"
        },
        {
          key: "supplierName",
          label: "Supplier",
          tdClass: "align-middle"
        },
        {
          key: "averageSavings",
          label: "Average Savings",
          tdClass: "align-middle"
        },
        {
          key: "priority",
          label: "Priority",
          tdClass: "align-middle"
        },
        {
          key: "action",
          label: "Action",
          tdClass: ["align-middle", "text-center-md-important"],
          thClass: ["align-middle", "text-center"]
        }
      ],
      contractsToSignFields: [
        {
          key: "name",
          label: "Site",
          tdClass: "align-middle"
        },
        {
          key: "product",
          label: "Type",
          tdClass: "align-middle"
        },
        {
          key: "newSupplierName",
          label: "Supplier",
          tdClass: "align-middle"
        },
        {
          key: "outDate",
          label: "Sign by",
          tdClass: "align-middle"
        }
      ]
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    supplyTypes: SupplyTypes.supplyTypes,
    formatSupplyTypes: SupplyTypes.toDisplay,
    formatDate: FormatHelper.formatDate,
    formatSaving: FormatHelper.formatNumberToDisplay,
    async fetchData() {
      this.busy = true;
      // Fetch data, make sure all calls are done before setting it
      try {
        let response = await Promise.all([this.getLatestReport(), this.getContracts()]);
        const reportResponse = response[0];
        const contractsResponse = response[1];

        this.supplies = reportResponse.supplies;
        this.supplySavings = reportResponse.supplySavings;

        //Create a map of contracts where the key is supplyId. Take the latest contract for a supply.
        contractsResponse.sort(this.compareContracts);
        this.contractsMap = Object.fromEntries(
          contractsResponse.map((contract) => [contract.supplyId, contract])
        );

        //Create a map of current deals where key is supplyId

        const currentDeals = reportResponse.deals
          ? reportResponse.deals.filter((deal) => deal.state == "CURRENT")
          : [];
        this.currentDealsMap = Object.fromEntries(
          currentDeals.map((deal) => [deal.supplyId, deal])
        );

        //Create a map of recommended deals where key is supplyId
        const recommendedDeals = reportResponse.deals
          ? reportResponse.deals.filter((deal) => deal.recommended == true)
          : [];
        this.recommendedDealsMap = Object.fromEntries(
          recommendedDeals.map((deal) => [deal.supplyId, deal])
        );

        if (reportResponse.supplies) {
          reportResponse.supplies.forEach((supply) => {
            //Gather the data required to work out actions
            var supplyCurrentDeal = this.currentDealsMap[supply.supplyId];
            var supplyCurrentDealExpiry = null;
            if (supplyCurrentDeal && supplyCurrentDeal.contractExpiry) {
              supplyCurrentDealExpiry = Date.parse(supplyCurrentDeal.contractExpiry);
            }
            var supplyRecommendedDeal = this.recommendedDealsMap[supply.supplyId];
            var latestContract = this.contractsMap[supply.supplyId];
            var latestContractSignedDate = null;
            if (latestContract && latestContract.signedDate) {
              latestContractSignedDate = Date.parse(latestContract.signedDate);
            }
            var liveContract = {
              ...supplyCurrentDeal,
              ...supply,
              ...latestContract
            };

            //Work out which action sections to populate
            if (supplyCurrentDeal) {
              var supplyCurrentSupplier = supplyCurrentDeal.supplierName;
              if (supplyCurrentSupplier) {
                this.liveContracts.push(liveContract);
              }
            }
            if (supply.noQuotes && supply.noQuotes == "NOT_ENOUGH_DATA") {
              this.billsRequired.push(liveContract);
            } else if (latestContract && latestContract.state == "OUT") {
              this.contractsToSign.push(liveContract);
            } else if (latestContract && latestContract.state == "SIGNED") {
              this.contractsBeingSwitched.push(liveContract);
            } else if (latestContract && latestContract.state == "LOCKED_IN") {
              this.contractsGoingLive.push(liveContract);
            } else if (supplyRecommendedDeal) {
              if (this.supplySavings[supply.supplyId]) {
                var recommendedItem = {};
                recommendedItem["supply"] = supply;
                recommendedItem["current"] = supplyCurrentDeal;
                recommendedItem["recommended"] = supplyRecommendedDeal;
                recommendedItem["saving"] = this.supplySavings[supply.supplyId];
                this.purchasingRecommendations.push(recommendedItem);
              }
            }
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.busy = false;
      }
    },

    async getLatestReport() {
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}savings?organisationId=${this.organisation.organisationId}`)
        .then((response) => {
          if (response.data.status === "success") {
            return response.data.savings[0];
          }
        })
        .catch((e) => {
          this.showAlert(
            "There was a problem getting the contracts for this organisation",
            "warning"
          );
          console.error(e);
        });
    },

    async getContracts() {
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}contracts/organisations/${this.organisation.organisationId}`)
        .then((response) => {
          if (response.data.status === "success") {
            return response.data.contracts;
          }
        })
        .catch((e) => {
          this.showAlert(
            "There was a problem getting the contracts for this organisation",
            "warning"
          );
          console.error(e);
        });
    },

    compareContracts(a, b) {
      if (a.createdDate < b.createdDate) {
        return -1;
      }
      if (a.createdDate > b.createdDate) {
        return 1;
      }
      return 0;
    },

    getAverageSavings(type) {
      var text;
      if (type) {
        switch (type) {
          case "ELECTRICITY":
            text = "942";
            break;
          case "GAS":
            text = "750";
            break;
          case "MOBILE":
            text = "514";
            break;
          case "CARD_PAYMENTS":
            text = "806";
            break;
          case "TELECOMS":
            text = "364";
            break;
          case "WATER":
            text = "107";
            break;
          case "WASTE":
            text = "327";
            break;
          case "FUEL":
            text = "928";
            break;
          default:
            text = "389";
        }
      }
      return text;
    },

    getPriority(type) {
      var text;
      if (type) {
        switch (type) {
          case "ELECTRICITY":
            text = "High";
            break;
          case "GAS":
            text = "High";
            break;
          case "CARD_PAYMENTS":
            text = "Medium";
            break;
          case "TELECOMS":
            text = "Medium";
            break;
          case "MOBILE":
            text = "Medium";
            break;
          default:
            text = "Low";
        }
      }
      return text;
    },
    showAlert(variant, message, title) {
      EventBus.$emit("show-toast", { message: message, variant: variant, title: title });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";

#client-actions {
  .reducer-divider {
    margin: 0.5rem 0;
  }
  h3 {
    margin-top: 2rem;
    opacity: 0.7;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-family: geomanistmedium;
  }
  .button-span {
    white-space: nowrap;
  }
  .actionSection {
    margin-top: 1rem;
  }
  .actionsTable {
    td {
      text-align: right;
      border-color: $color-grey-lighter3;
      border-width: 1px;
    }
    ::before {
      text-align: left;
    }
    tr {
      margin-bottom: 20px;
      border-style: solid;
      border-width: 1px;
      border-color: $color-grey-lighter1;
      box-shadow: 0px 0px 5px rgba($color: #424242, $alpha: 0.3);
    }
    thead th {
      background-color: $color-grey-lighter3;
    }
    @media (min-width: 768px) {
      border-top-style: solid !important;
      border-bottom-style: solid;
      border-width: 1px;
      border-color: $color-grey-lighter2;
      .text-center-md-important {
        text-align: center;
      }
      th,
      td {
        text-align: left;
        border-top-style: none;
        border-bottom-style: none;
        border-left-style: solid;
        border-right-style: solid;
        border-width: 1px;
        border-color: $color-grey-lighter2;
      }

      tr {
        box-shadow: none;
        border-style: none;
      }
    }
  }
  .tableButton {
    background-color: white;
    color: $color-font-headings;
  }
}
</style>
