<template>
  <b-dropdown
    class="card-menu mr-1"
    toggle-class="text-decoration-none"
    variant="outline-secondary"
    v-if="canSeeData()"
    lazy
    :no-caret="noCaret"
    dropright
  >
    <template slot="button-content">
      <slot></slot>
      <span class="sr-only">Options</span>
    </template>
    <b-dropdown-header>{{ organisation.name }}</b-dropdown-header>
    <b-dropdown-item
      :to="{
        name: 'spotlight',
        params: { organisationId: link.organisationId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'hand-spock']" class="text-muted mr-2" />Spotlight
    </b-dropdown-item>
    <b-dropdown-item
      :to="{
        name: 'organisation-details',
        params: { organisationId: link.organisationId }
      }"
    >
      <font-awesome-icon :icon="['fas', 'info-circle']" class="text-muted mr-2" />Details
    </b-dropdown-item>

    <b-dropdown-item
      :to="{
        name: 'savings',
        params: { organisationId: link.organisationId }
      }"
      v-if="isArchived()"
    >
      <font-awesome-icon :icon="['fas', 'pound-sign']" class="text-muted mr-2" />Savings
      <b-badge variant="danger">Archived</b-badge>
    </b-dropdown-item>
    <b-dropdown-item v-if="organisation.connected" @click="disconnect">
      <font-awesome-icon :icon="['fas', 'user-slash']" class="text-muted mr-1" />Disconnect
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import RoleHelper from "@/helper/rolehelper";

export default {
  name: "OrganisationCardMenu",
  props: {
    organisation: Object,
    link: Object,
    report: { type: Object, default: null },
    noCaret: Boolean
  },
  methods: {
    disconnect() {
      EventBus.$emit("disconnect-organisation", this.organisation);
    },
    canSeeData() {
      var canSeeData = false;

      if (this.link.roles != null && this.link.roles.length > 0) {
        for (var role of this.link.roles) {
          if (role.id == RoleHelper.FULL_MEMBER_ID) {
            canSeeData = true;
            break;
          }
        }
      }
      return canSeeData;
    },
    isArchived() {
      return this.report && this.report.archived && this.report.state == "complete";
    }
  }
};
</script>

<style lang="scss">
.card-menu {
  a.dropdown-item {
    text-decoration: none !important;
    color: initial !important;
  }
}
</style>