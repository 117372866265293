<template>
  <div>
    <b-modal
      ref="clientDashboardContact"
      id="client-dashboard-contact"
      @ok="handleOk"
      @hidden="$emit('cancelled')"
      hide-header
      hide-footer
      lazy
    >
      <div>
        <h5 class="mb-3">{{ title }}</h5>

        <h6 class="mb-3">
          <span>You are requesting a {{ title }}.</span>
        </h6>

        <h6>
          To contact us, you can email
          <a href="mailto:support@reducer.co.uk" v-bind:style="{ color: primaryColor }"
            >support@reducer.co.uk</a
          >, or call us on
          <a href="tel:+442039704884" v-bind:style="{ color: primaryColor }">+44 20 4571 7704</a>.
        </h6>

        <h6 v-if="user.phoneNumbers[0].number">
          The number we have for you is
          <span v-bind:style="{ color: primaryColor }">{{ user.phoneNumbers[0].number }}</span
          >. If you need to update your number, please provide the new number below.
          <br />
          <br />
        </h6>

        <h6 v-else>Please provide a number we can contact you on.</h6>

        <div class="mt-2">
          <b-form-input v-model="providedPhoneNumber" placeholder="Contact phone number" />
        </div>

        <small v-if="phoneErrorMessage" class="modalError p-0 px-2 py-1">{{
          phoneErrorMessage
        }}</small>
      </div>
      <div class="p-0 pt-2 float-right">
        <b-button variant="secondary" @click="$emit('cancelled')">Cancel</b-button>
        <b-button
          class="ml-2 gtm-sendme-contact"
          variant="primary"
          @click="handleOk"
          v-bind:style="{ 'background-color': secondaryColor, 'border-color': secondaryColor }"
          >Send Request</b-button
        >
      </div>
    </b-modal>

    <b-modal v-model="showSentRequestModal" hide-header hide-footer lazy>
      <h5 class="my-5">
        Thanks. We have receieved your request and will get back to you as soon as possible.
      </h5>

      <div class="float-right">
        <b-button variant="secondary" @click="showSentRequestModal = false">Ok</b-button>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#client-dashboard-contact {
  .modalError {
    color: red;
  }
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import OrganisationEventHelper from "@/helper/organisationeventhelper";
import Console from "@/console";

import CognitoAuth from "@/cognito/cognitoauth";
import TokenHelper from "@/cognito/tokenhelper";
import PhoneHelper from "@/helper/phonehelper";
export default {
  name: "ClientDashboardContact",
  props: {
    organisationId: String,
    title: String,
    type: String,
    buttonText: String,
    primaryColor: {
      type: String,
      default: "#F40C8F"
    },
    secondaryColor: {
      type: String,
      default: "#007BFF"
    }
  },
  data() {
    return {
      user: {
        phoneNumbers: [{ number: null }]
      },
      providedPhoneNumber: null,
      showSentRequestModal: false,
      phoneErrorMessage: null,
      mydeal: null
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    sendRequestRefresh: OrganisationEventHelper.sendRequestRefresh,
    sendRequestCallback: OrganisationEventHelper.sendRequestCallback,

    async handleOk(evt) {
      this.phoneErrorMessage = null;
      if (
        this.type == "requestCallback" &&
        !PhoneHelper.isValid(this.providedPhoneNumber) &&
        (!this.user.phoneNumbers || !this.user.phoneNumbers[0].number)
      ) {
        this.phoneErrorMessage = "Please enter a valid phone number for the callback";
        return;
      }
      if (
        PhoneHelper.isValid(this.providedPhoneNumber) ||
        this.providedPhoneNumber == null ||
        this.providedPhoneNumber == ""
      ) {
        if (this.providedPhoneNumber != null && this.providedPhoneNumber != "")
          await this.setUserPhoneNumber();
        var sendEvent = await this.sendRequest();
        if (sendEvent != false) {
          this.$refs.clientDashboardContact.hide();
          this.showSentRequestModal = true;
        } else {
          EventBus.$emit("show-toast", {
            message: "Due an unexpected error we couldn't submit your request.",
            variant: "warning"
          });
        }
      } else {
        this.phoneErrorMessage = "Please enter a valid phone number";
        evt.preventDefault();
      }
    },

    async getUserId() {
      const token = await CognitoAuth.getCurrentUserAccessToken();
      return TokenHelper.parseJwtToken(token).sub;
    },

    async getUserInfo() {
      try {
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}users/${await this.getUserId()}`);

        if (response.data.status === "success") {
          this.user = response.data.user;

          if (
            typeof this.user.phoneNumbers == "undefined" ||
            !PhoneHelper.isValid(this.user.phoneNumbers[0].number)
          ) {
            this.user["phoneNumbers"] = [
              {
                number: null
              }
            ];
          }
        } else {
          this.showAlert(
            "Sorry, we had a problem getting your user data from our database.",
            "warning"
          );
        }
      } catch (err) {
        Console.error(err);
        this.showAlert(
          "Sorry, we had a problem getting your user data from our database",
          "warning"
        );
      }
    },

    async setUserPhoneNumber() {
      try {
        let client = await ApiHelper.http();
        var response = await client.post(`${ApiHelper.endPoint()}users/${await this.getUserId()}`, {
          user: {
            phoneNumbers: [{ number: this.providedPhoneNumber }]
          }
        });
        if (response.data.status === "success") {
          OrganisationEventHelper.sendAddedPhone(
            this.organisationId,
            this.created,
            this.providedPhoneNumber
          );

          if (this.user) {
            this.user.phoneNumbers[0].number = this.providedPhoneNumber;
          }
        } else {
          this.showAlert(
            "Sorry, we had a problem saving your phone number. We may ask you for the number again in future.",
            "warning"
          );
        }
      } catch (err) {
        Console.error(err);
        this.showAlert(
          "Sorry, we had a problem saving your phone number. We may ask you for the number again in future.",
          "warning"
        );
      }
    },

    showAlert(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    async sendRequest() {
      console.log(this.organisationId);
      if (this.type == "requestRefresh") {
        this.sendRequestRefresh(this.organisationId);
      } else if (this.type == "requestCallback") {
        this.sendRequestCallback(this.organisationId);
      } else {
        console.error("Unknown event request event", this.type);
      }
    }
  }
};
</script>
