<template>
  <div id="client-organisation" class="mt-5">
    <ClientDashboardContact
      :type="requestType"
      :title="contactTitle"
      :organisationId="organisation.organisationId"
      @cancelled="hideContactModal()"
    />

    <div id="org-menu">
      <!-- Connection status -->
      <div
        v-if="['Xero', 'QuickBooks'].includes(this.organisation.organisationSource)"
        :class="'connection-status' + (!organisation.connected ? ' disconnected' : '')"
        v-b-tooltip="connectionText()"
      >
        <img
          v-if="organisation.organisationSource.toLowerCase() == 'xero'"
          src="@/assets/images/xero.png"
          alt
          height="35"
        />
        <img
          v-else-if="organisation.organisationSource.toLowerCase() == 'quickbooks'"
          src="@/assets/images/qb.png"
          alt
          height="35"
        />
        <font-awesome-icon v-else class="menu-icon m-2" :icon="['fas', 'link']" />
      </div>

      <organisation-card-menu :organisation="organisation" :link="link" :report="report" no-caret>
        <font-awesome-icon id="menu-icon" :icon="['fas', 'ellipsis-v']" />
      </organisation-card-menu>
    </div>

    <h1 id="org-title">{{ organisation.name }}</h1>

    <!-- Progress bar -->
    <organisation-progress
      v-if="showProgress"
      :state="state"
      :platform="organisation.organisationSource"
      :report="report"
      no-images
      class="my-5"
    />

    <!-- Savings state -->
    <div v-if="savingsState">
      <!-- Report complete -->
      <div v-if="savingsState == 'REPORT_COMPLETE'" class="card-text">
        <b-row class="mt-3" align-v="center">
          <b-col cols="12" sm="6" lg="3" class="text-center justify-content-center">
            <GradientCircle
              colorA="#ff0090"
              colorB="#007cf9"
              size="165px"
              thickness="7px"
              class="main-circle"
            >
              <h3 class="headlinePrice mb-1">
                <b>{{
                  report.totalSavings
                    ? $currencySymbol + formatSaving(report.totalSavings)
                    : $currencySymbol + "0"
                }}</b>
              </h3>
              <h4 class="headlineTitle mb-0">Annual Savings</h4>
            </GradientCircle>
            <div>
              <b-button
                class="savings-button mt-3 px-5"
                :to="{ name: 'savings', params: { organisationId: organisation.organisationId } }"
                >View Report</b-button
              >
            </div>
          </b-col>
          <b-col cols="12" lg="4" class="d-none d-lg-block">
            <div class="text-center">
              <h5 id="savingsIncludeHeader">Savings include</h5>
              <div class="reducer-divider my-3"></div>
            </div>
            <p v-for="(amount, type) in report.supplyTypeSavings" :key="type">
              <b-row align-h="between" class="px-3">
                <b-col class="savingsAreaAmount" cols="6">{{ supplyTypesDisplay(type) }}</b-col>
                <b-col class="savingsAreaAmount text-right" cols="6"
                  >{{ $currencySymbol }}{{ formatSaving(amount) }}</b-col
                >
              </b-row>
            </p>
          </b-col>
          <b-col class="mt-4 mt-sm-0" cols="12" sm="6" lg="5">
            <b-row class="text-center justify-content-center p-2">
              <b-col cols="6" class="px-3 px-sm-2">
                <b-button
                  class="phoneButton p-2 reducer-box-shadow w-100 h-100"
                  v-on:click="showContactModal('Report Refresh', 'requestRefresh')"
                >
                  Request<br /><span class="noBreak">report refresh</span><br /><font-awesome-icon
                    class="phoneIcon"
                    :icon="['fas', 'sync']"
                  />
                </b-button>
              </b-col>
              <b-col cols="6" class="px-3 px-sm-2">
                <b-button
                  class="phoneButton p-2 reducer-box-shadow w-100 h-100"
                  v-on:click="showContactModal('Call back', 'requestCallback')"
                >
                  Request<br /><span class="noBreak">a call back</span><br /><font-awesome-icon
                    class="phoneIcon"
                    :icon="['fas', 'phone-alt']"
                  />
                </b-button>
              </b-col>
            </b-row>
            <b-row class="text-center justify-content-center p-3">
              <div id="findMoreSavings" class="w-100 p-3">
                <h5>Find More Savings</h5>
                <p>Upload your bills below and we’ll begin updating your purchasing report.</p>
                <b-button
                  class="phoneButton px-3"
                  @click="$refs.fileUploadModal.show(organisation.organisationId)"
                  ><font-awesome-icon
                    class="phoneIcon mr-2"
                    :icon="['fas', 'arrow-to-top']"
                  />Upload Bills</b-button
                >
              </div>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <!-- No savings -->
      <div v-else-if="savingsState.includes('NOSAVINGS')" class="card-text">
        <h5>Analysis Complete</h5>
        <h2>
          We haven't found any savings for {{ formatOrgName(organisation.name) }} at the moment
        </h2>
        <p v-if="report.state == 'noneFound'">
          This is because you're either in a good deal or in a contract.
        </p>
        <p v-else-if="report.state == 'noneFoundNoMatches'">
          This is because we couldn't find any spend data in the areas that we cover.
        </p>
        <p v-else-if="report.state == 'noneFoundInsufficientData'">
          We successfully identified bills in some of the spend areas that we cover but there wasn't
          enough data for us to provide you with a quote.
        </p>

        <p v-if="report.state == 'noneFound'">
          Don't worry, we've kept note of your contract end dates and will be in touch once we can
          re-run our cost saving analysis.
        </p>
        <p v-else>
          Don't worry, we can still find you savings. Simply
          <b-link @click="$refs.fileUploadModal.show(organisation.organisationId)">upload</b-link>
          the relevant bills and we will perform our savings analysis.
        </p>
      </div>

      <!-- Gathering/queued -->
      <div v-else-if="['GATHERING', 'QUEUED'].includes(savingsState)" class="card-text">
        <h5>Not too long now!</h5>
        <h2>We're gathering {{ formatOrgName(organisation.name) }} data</h2>
        <p>
          We only collect data that we need to provide you with a detailed savings report.
          <br />This process shouldn't take any longer than a few minutes. We will then start our
          analysis.
        </p>
      </div>

      <!-- Error during extract -->
      <div v-else-if="savingsState == 'ERROR'" class="card-text">
        <h5>Error</h5>
        <h2>
          Oops! We had an issue starting the analysis for {{ formatOrgName(organisation.name) }}
        </h2>
        <p class="my-1">
          This could have happened due to a server timeout or some unexpected data.
        </p>
        <p class="my-1">
          Please retry by starting a new analysis. If the issue persists, please contact
          <a href="mailto:help@reducer.co.uk">help@reducer.co.uk</a>
        </p>
      </div>

      <!-- Analysis in progress -->
      <div v-else-if="savingsState == 'REPORT_GENERATION'" class="card-text">
        <h5>Not too long now!</h5>
        <h2>We're running a cost saving analysis for {{ formatOrgName(organisation.name) }}</h2>
        <p class="my-1">
          We've successfuly collected the data we need to provide you with a detailed savings
          report.
          <br />Once the analysis is complete we will drop you an email with a link to your shiny
          new report.
        </p>
      </div>

      <!-- Analysis in progress -->
      <div v-else-if="savingsState == 'ARCHIVED'" class="card-text">
        <h5>Not too long now!</h5>
        <h2>We're running a new cost saving analysis for {{ formatOrgName(organisation.name) }}</h2>
        <p class="my-1">
          We've successfuly collected the data we need to provide you with a detailed savings
          report.
          <br />Once the analysis is complete we will drop you an email with a link to your shiny
          new report.
        </p>
        <p v-if="isArchived()">
          You can still access your
          <b-link :to="{ name: 'savings', params: { organisationId: organisation.organisationId } }"
            >current savings report here</b-link
          >.
        </p>
      </div>
    </div>

    <ClientActions :key="organisation.organisationId" :organisation="organisation" />
    <FileUploadModal ref="fileUploadModal" />
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";
import SupplyTypes from "@/helper/supplytypehelper";
import ClientActions from "@/components/ClientActions";
import GradientCircle from "@/components/savingsreport/GradientCircle";
import ClientDashboardContact from "@/components/savingsreport/ClientDashboardContact";
import OrganisationCardMenu from "@/components/OrganisationCardMenu";
import OrganisationProgress from "@/components/OrganisationProgress";
import FileUploadModal from "@/components/modals/FileUploadModal";

export default {
  name: "ClientOrganisation",
  components: {
    ClientActions,
    GradientCircle,
    ClientDashboardContact,
    OrganisationCardMenu,
    OrganisationProgress,
    FileUploadModal
  },
  props: {
    organisation: {
      type: Object,
      required: true
    },
    link: Object,
    state: String,
    report: Object
  },
  data() {
    return {
      contactTitle: "",
      requestType: null
    };
  },
  computed: {
    savingsState: function() {
      return FormatHelper.getSavingsState(this.state, this.report);
    }
  },
  methods: {
    formatOrgName: FormatHelper.orgShortName,
    formatSaving: FormatHelper.formatNumberToDisplay,
    supplyTypesDisplay: SupplyTypes.toDisplay,
    connectionText() {
      if (this.organisation.connected) {
        return `Connected to ${this.organisation.organisationSource}`;
      } else {
        return `Disconnected from ${this.organisation.organisationSource}`;
      }
    },
    showContactModal(title, type) {
      this.contactTitle = title;
      this.requestType = type;
      this.$bvModal.show("client-dashboard-contact");
    },
    hideContactModal() {
      this.$bvModal.hide("client-dashboard-contact");
    },
    isArchived() {
      return this.report && this.report.archived && this.report.state == "complete";
    },
    showProgress() {
      this.links.length > 1;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";

#client-organisation {
  h2,
  h5 {
    font-family: geomanistmedium;
  }
  h5 {
    opacity: 0.7;
    text-transform: uppercase;
    font-size: 1.05rem;
  }
  #org-title {
    max-width: 80%;
  }
  .savings-button {
    border: none;
    background-color: $color-pink-main;
    font-weight: bold;
  }
  #savingsIncludeHeader,
  .savingsAreaAmount {
    color: $color-font-headings;
  }
  #org-menu {
    float: right;
    display: inline-flex;
    margin-top: 10px;
    button {
      border: none !important;
      padding: 0;
      background-color: white !important;
      &:hover {
        background-color: $color-grey-lighter5 !important;
      }
      &:active {
        background-color: $color-grey-lighter5 !important;
      }
    }
  }
  #menu-icon {
    font-size: 1.5rem;
    color: $color-grey-lighter2;
    margin: 6px 10px 0px 10px !important;
  }
  .connection-status {
    margin-right: 10px;
    border-radius: 50%;
    background-color: $color-grey-lighter3;
    width: 2rem;
    height: 2rem;
    img {
      border: 2px solid $color-grey-lighter3;
      border-radius: 50%;
    }
    &.disconnected {
      img {
        filter: grayscale(100) !important;
        opacity: 0.7;
      }
    }
  }
  #findMoreSavings {
    background-color: $color-grey-lighter4;
    h5,
    p {
      color: $color-font-headings;
    }
  }
  .noBreak {
    white-space: nowrap;
  }
  .phoneButton {
    background-color: white;
    color: $color-font-headings;
  }
  .phoneIcon {
    color: $color-pink-main;
  }
}
</style>
