<template>
  <div class="organisation-progress" :active-step="activeStep" :error="error">
    <b-row v-if="!noImages" class="progress-pictures">
      <b-col v-for="(step, i) in progressSteps" :key="step.id">
        <img :class="activeClass(i)" :src="step.img" alt="" />
      </b-col>
    </b-row>

    <div class="org-progress-bar">
      <b-row class="progress-steps">
        <b-col v-for="(step, i) in progressSteps" :key="i">
          <div :class="'step ' + activeClass(i)">
            <span v-if="activeClass(i) == 'completed' || activeStep == 4">
              <font-awesome-icon :icon="['fas', 'check']" />
            </span>
            <span v-else-if="activeClass(i) == 'error'">
              <font-awesome-icon :icon="['fas', 'plus']" class="rotate-icon" />
            </span>
            <span v-else-if="state">{{ i }}</span>
          </div>
        </b-col>
        <div class="progress-background">
          <div class="progress"></div>
        </div>
      </b-row>
    </div>

    <b-row class="progress-text">
      <b-col v-for="(step, i) in progressSteps" :key="i">
        <p :class="activeClass(i)">{{ step.text }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "OrganisationProgress",
  props: {
    state: String,
    platform: String,
    noImages: Boolean,
    report: Object
  },
  data() {
    return {
      activeStep: 0,
      error: false,
      progressSteps: {
        1: {
          text: `Connect to ${this.platform}`,
          img: require(`@/assets/images/${
            this.platform.toLowerCase() == "xero" ? "xero" : "qb"
          }.png`)
        },
        2: { text: "Gathering data", img: require("@/assets/images/cloud_down.png") },
        3: { text: "Analysis in progress", img: require("@/assets/images/analyse.png") },
        4: { text: "Report complete", img: require("@/assets/images/confetti.png") }
      }
    };
  },
  watch: {
    state: {
      immediate: true,
      handler(state) {
        let savingsState = FormatHelper.getSavingsState(state, this.report);
        if (savingsState) {
          if (savingsState == "REPORT_COMPLETE") {
            this.activeStep = 4;
          } else if (savingsState.includes("NOSAVINGS")) {
            this.progressSteps["4"].text = "No savings found";
            this.progressSteps["4"].img = require("@/assets/images/warning.png");
            this.activeStep = 4;
          } else if (state == "ERROR") {
            this.error = true;
            this.activeStep = 2;
          } else if (state == "GATHERING" || state == "QUEUED") {
            this.activeStep = 2;
          } else {
            this.activeStep = 3;
          }
        }
      }
    }
  },
  methods: {
    activeClass(step) {
      if (this.error && step == 2) {
        return "error";
      } else if (step == this.activeStep) {
        return "active";
      } else if (step < this.activeStep) {
        return "completed";
      } else {
        return "disabled";
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";

.organisation-progress {
  .rotate-icon {
    transform: rotate(45deg);
  }

  text-align: center;
  .progress-pictures {
    justify-content: space-between;
    align-items: baseline;
    img {
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 50rem;
      box-shadow: $light-shadow;
      padding: 0.8rem;
      margin-bottom: 0.5rem;
      &.active {
        width: 5rem;
        height: 5rem;
        box-shadow: 1px 1px 5px 3px rgba($color: black, $alpha: 0.2);
      }
      &.disabled,
      &.error {
        filter: grayscale(100);
        opacity: 0.5;
        width: 4rem;
        height: 4rem;
      }
    }
  }
  .org-progress-bar {
    margin: 1rem 0;
    border-radius: 2rem;
    position: relative;
    overflow: visible;
    .progress-background,
    .progress {
      width: 100%;
      height: 0.5rem;
      position: absolute;
    }
    .progress-background {
      background-color: $color-grey-lighter5;
      max-width: 77%;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: -1;
    }
    .progress {
      background-image: linear-gradient(to left, $color-pink-main, $color-blue-main);
      max-width: 0%;
      animation: progress-grow 2s linear;
    }
    .progress-steps {
      justify-content: space-between;
      align-items: center;
      .step {
        margin: 0 auto;
        z-index: 1;
        width: 2rem;
        height: 2rem;
        background-color: white;
        border-radius: 50rem;
        border: $light-border;
        box-shadow: $light-shadow;
        &.active {
          height: 2.1rem;
          width: 2.1rem;
          border: none;
          background-color: $color-pink-main !important;
          span {
            font-size: 1.3rem;
            color: white;
          }
        }
        &.completed {
          &:first-child {
            svg {
              color: $color-blue-darker1;
            }
          }
        }
        span {
          color: $color-font-headings;
          font-size: 1.2rem;
          font-weight: 800;
        }
      }
    }
  }
  .progress-text {
    justify-content: space-between;
    p {
      font-weight: 800;
      text-align: center;
      line-height: 1.5rem;
      &.active {
        color: $color-font-headings;
        font-size: 1.3rem;
      }
    }
  }

  // Styling for different progress steps
  &[active-step="2"] {
    .progress {
      max-width: 33% !important;
    }
    &[error="true"] {
      .progress {
        background: $color-font-para;
        opacity: 0.5;
      }
      .progress-steps > :nth-child(2) svg {
        color: $color-font-para !important;
        opacity: 0.7;
      }
    }
  }
  &[active-step="3"] {
    .progress {
      max-width: 66% !important;
    }
    .progress-steps > :nth-child(2) svg {
      color: $color-purple-main !important;
    }
  }
  &[active-step="4"] {
    .progress {
      max-width: 100% !important;
    }
    .progress-steps > :nth-child(2) svg {
      color: $color-purple-bluer !important;
    }
    .progress-steps > :nth-child(3) svg {
      color: $color-purple-pinker !important;
    }
    .step.active {
      max-width: 2rem;
      max-height: 2rem;
      span {
        font-size: 1.2rem !important;
      }
    }
  }

  @keyframes progress-grow {
    0% {
      width: 0%;
    }
    50% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .progress-pictures img {
      width: 3.5rem;
      height: 3.5rem;
      &.active {
        width: 4rem;
        height: 4rem;
      }
    }
    .progress-text p {
      font-size: 0.8rem !important;
      &.active {
        font-size: 1rem !important;
      }
    }
    .progress-steps .step {
      width: 1.7rem !important;
      height: 1.7rem !important;
      span {
        font-size: 1.1rem !important;
      }
      &.active {
        width: 2.1rem !important;
        height: 2.1rem !important;
        span {
          font-size: 1.3rem !important;
        }
      }
    }
  }
}
</style>